import React, { useEffect, useState } from "react";
import { ErrorMessage, LoadingMessage } from "../../components";
import { get, ENDPOINTS } from "../../services/checkout";
import { Grid } from "basis";
import { logger } from "../../core";
import { isBrowser } from "../../utils";
import { setManualPayment } from "../../layout/checkout/manualPayments";
import { TRANSACTION_STATUS } from "../../core/constants";
const Initiate = () => {
  let transactionId,
    token,
    isManual = false;
  if (isBrowser()) {
    const params = new URLSearchParams(unescape(window.location.search));
    transactionId = params.get("transactionId");
    token = params.get("token");
    isManual = params.get("isManual");
  }

  const [errorType, setErrorType] = useState();

  useEffect(() => {
    const initiate = async () => {
      if (!transactionId || !token) {
        setErrorType("invalid-request");
        return;
      }

      logger.addContext("transactionId", transactionId);
      try {
        const response = await process(transactionId, token);
        if (!response) {
          setErrorType("invalid-request");
          return;
        }

        if (response.toUpperCase() === TRANSACTION_STATUS.CANCELLED.toUpperCase()) {
          setErrorType("payment-cancelled");
          return;
        }

        if (isBrowser()) {
          setManualPayment(isManual);
          window.location.replace(response);
        }
      } catch (err) {
        logger.error(err.message);
        setErrorType("invalid-request");
      }
    };

    initiate();
  }, [transactionId, token, isManual]);

  const process = async (transactionId, token) => {
    const response = await get({
      endpoint: ENDPOINTS.PURCHASE_INITIATE,
      token,
      params: {
        transactionId,
      },
    });

    return response;
  };

  if (!errorType) {
    return <LoadingMessage />;
  }

  return (
    <Grid preset="page" rowsGap={4}>
      <Grid.Item colSpan="all" colSpan-lg="2-10" colSpan-xl="4-8">
        <ErrorMessage type={errorType} />
      </Grid.Item>
    </Grid>
  );
};

export default Initiate;
